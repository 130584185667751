@import "components/header";

body {
  font-family: 'Roboto Flex', sans-serif;
  color: #1C3E56;
  background-color: #F5E8DF;
  letter-spacing: 0.5px;
}

.row {
  margin-left: -8px;
  margin-right: -8px;

  > * {
    padding-left: 8px;
    padding-right: 8px;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, {
  color: $lightBlue;
  font-family: 'Dela Gothic One';
}

h1, .h1 {
  font-size: 32px;
  font-weight: 400;
  line-height: 130%;

  @include media-breakpoint-up(lg) {
    font-size: 64px;
    line-height: 110%;
  }
}

h2, .h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 130%;

  @include media-breakpoint-up(lg) {
    font-size: 48px;
    line-height: 110%;
  }
}

h3, .h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 110%;
  font-family: 'Roboto Flex', sans-serif;

  @include media-breakpoint-up(lg) {
    font-size: 24px;
    line-height: 110%;
  }
}

 p {
   font-size: 14px;
   font-weight: 500;
   line-height: 135%;
 }
 ul {
   list-style: none;
   padding-left: 17px;

   li {
     position: relative;
     font-size: 14px;
     font-weight: 500;
     line-height: 135%;

     &:before {
       content: "•";
       position: absolute;
       top: 50%;
       left: -18px;
       font-size: 20px;
       color: #19849B;
       transform: translateY(-50%);
     }
     &:not(:last-child) {
       margin-bottom: 14px;
     }
   }
 }

.container {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1232px;
}
.content_body {
  padding-top: 38px;

  @include media-breakpoint-up(lg) {
    padding-top: 110px;

    #type_index & {
      padding-top: 0px;
    }
    #type_cart & {
      padding-top: 50px;
    }
  }
}

#customers_authorize {
  max-width: 458px;
}

#type_index {
}
.hi-block {
  position: relative;
  overflow: hidden;
  margin-bottom: 58px;

  .inner-wrp {
    .texts-block {
      margin-bottom: 14px;
      text-align: center;

      h1 {
        margin-top: 0px;
        margin-bottom: 12px;
        color: $lightBlue;
      }
      p {
        margin-bottom: 23px;
        font-size: 18px;
        font-weight: 500;
        line-height: 135%;
        letter-spacing: 0.7px;
      }
      .btn {}
    }
    .banner-block {
      position: relative;
      display: grid;
      justify-content: center;

      img {
        width: 412px;
        max-width: initial;
      }
      .phrase {
        position: absolute;
        margin: 0px;
        padding: 21px 24px;
        width: 285px;
        font-size: 14px;
        font-weight: 500;
        line-height: 135%;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 11.74322px 57.24819px 0px rgba(100, 178, 223, 0.10), 0px 5.87161px 66.0556px 0px rgba(250, 85, 131, 0.08);

        &.phrase-1 {
          bottom: 137px;
          left: 50%;
          transform: translateX(-65%);
        }
        &.phrase-2 {
          bottom: 46px;
          right: 50%;
          transform: translatex(67%);
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 120px;

    .inner-wrp {
      display: grid;
      grid-template-columns: minmax(0, 5fr) minmax(0, 6fr);
      align-items: center;
      column-gap: 35px;

      .texts-block {
        margin-bottom: 135px;
        text-align: left;

        h1 {
          margin-bottom: 24px;
        }
        p {
          margin-bottom: 32px;
          font-size: 20px;
          line-height: 150%;
        }
        .btn {}
      }
      .banner-block {
        img {
          width: 572px;
        }
        .phrase {
          padding: 34px 64px;
          width: 416px;
          font-size: 18px;
          border-radius: 12px;

          &.phrase-1 {
            bottom: 181px;
            transform: translateX(-75%);
          }
          &.phrase-2 {
            bottom: 53px;
            transform: translatex(66%);
          }
        }
      }
    }
  }
}
.features-block {
  margin-bottom: 80px;

  .inner-wrp {
    .text-wrp {
      margin-bottom: 24px;
      text-align: center;

      h2 {
        margin-bottom: 15px;
        max-width: 700px;
      }
      p {
        margin: 0px;
        font-size: 14px;
        font-weight: 500;
        line-height: 135%;
      }
    }
    .features-wrp {
      ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: 6px;
        column-gap: 8px;
        padding: 0px;
        margin: 0px;

        li {
          display: grid;
          grid-template-columns: minmax(0, 49px) minmax(0, 1fr);
          align-items: center;
          column-gap: 20px;
          margin: 0px;
          padding: 29px 30px 29px 20px;
          background-color: #ffffff;
          border-radius: 4px;
          box-shadow: 0px 4px 34px 0px rgba(6, 19, 54, 0.05);

          img {}
          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 135%;
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .inner-wrp {
      .features-wrp {
        ul {
          li {
            &:nth-child(2n) {
              grid-template-columns: minmax(0, 1fr) minmax(0, 49px);
              padding: 29px 20px 29px 30px;

              img {
                order: 2;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 140px;

    .inner-wrp {
      .text-wrp {
        margin-bottom: 40px;
        text-align: left;

        h2 {
          margin-bottom: 24px;
        }
        p {
          font-size: 16px;
        }
      }
      .features-wrp {
        ul {
          grid-template-columns: repeat(4, minmax(0, 1fr));

          li {
            grid-template-columns: minmax(0, 1fr);
            grid-template-rows: minmax(0, 55px) minmax(0, 1fr);
            row-gap: 30px;
            padding: 40px 30px;

            img {}
            span {
              align-self: start;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.buy-coupon-block {
  margin-bottom: 20px;

  .inner-wrp {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 30px;
    text-align: center;
    background-color: #ffffff;
    filter: drop-shadow(0px 8px 39px rgba(0, 0, 0, 0.10));
    border-radius: 4px;

    h2 {}
    p {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
      line-height: 135%;
    }
    img {
      margin-bottom: 8px;
      min-width: 504px;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 36px;

    .inner-wrp {
      padding: 80px 30px 56px 30px;

      h2 {
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
      }
      img {
        position: relative;
        left: -137px;
        margin-top: 7px;
        max-width: initial;
        width: calc(100% + 290px);
      }
    }
  }
}

.about-block {
  margin-bottom: 80px;

  .inner-wrp {
    h1 {
      margin-bottom: 40px;
    }
    p {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 135%;
      letter-spacing: 0.5px;

      & + p {
        margin-top: -12px;
      }
    }
    img {
      margin-bottom: 20px;
      border-radius: 4px;
    }
    .achievements-wrp {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      row-gap: 6px;
      margin-top: 40px;

      .achievement {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 36px);
        column-gap: 20px;
        padding: 26px 14px 26px 30px;
        border-radius: 4px;
        background: #ffffff;
        box-shadow: 0px 4px 34px 0px rgba(6, 19, 54, 0.05);

        .text-wrp {
          display: grid;

          .title {
            font-size: 18px;
            font-weight: 500;
            line-height: 135%;
            letter-spacing: 0.7px;
          }
          .value {
            font-size: 32px;
            font-weight: 400;
            line-height: 130%;
            font-family: 'Dela Gothic One', cursive;
          }
        }
        img {
          margin-top: -4px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 140px;

    .inner-wrp {
      display: grid;
      grid-template-areas: "title photo"
                           "intro photo"
                           "desc photo"
                           "achievements achievements";
      grid-template-columns: minmax(0, 7fr) minmax(0, 10fr);
      grid-template-rows: auto auto minmax(0, 1fr) auto;
      column-gap: 30px;
      align-items: start;

      h1 {
        grid-area: title;
        margin-bottom: 30px;
      }
      p {
        margin-bottom: 32px;
        font-size: 18px;
        letter-spacing: 0.8px;

        & + p {
          margin-top: -26px;
        }
      }
      .intro {
        grid-area: intro;
      }
      .desc {
        grid-area: desc;
      }
      picture {
        grid-area: photo;

        img {}
      }
      .achievements-wrp {
        grid-area: achievements;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 8px;
        margin-top: 60px;

        .achievement {
          grid-template-columns: minmax(0, 1fr) minmax(0, 42px);
          padding: 36px 30px 30px 40px;

          .text-wrp {
            .title {
              font-size: 20px;
            }
            .value {
              font-size: 48px;
            }
          }
          img {}
        }
      }
    }
  }
}
.moments-block {
  margin-bottom: 80px;

  .inner-wrp {
    h2 {
      margin-bottom: 24px;
    }
    .moments-wrp {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(25, minmax(0, 0.11fr));
      column-gap: 6px;
      row-gap: 6px;

      .moment {
        position: relative;
        overflow: hidden;
        border-radius: 4px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .inner-wrp {
      .moments-wrp {
        .moment {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            grid-row: 16 / 11 span;
            grid-column: 1/2;
          }
          &:nth-child(4) {
            grid-row: 7 / span 9;
            grid-column: 1/2;
          }
          &:nth-child(5) {
            grid-row: 17 / 10 span;
            grid-column: 2/3;
          }
          &:nth-child(6) {
            grid-row: 11 / 6 span;
            grid-column: 2/3;
          }
          &:nth-child(7) {
            grid-row: 1 / span 10;
            grid-column: 2/3;
          }
          &:nth-child(8) {
            grid-row: 1 / span 6;
            grid-column: 1/2;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 140px;

    .inner-wrp {
      h2 {
        margin-bottom: 48px;
      }
      .moments-wrp {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-template-rows: repeat(12, minmax(0, 0.13fr));
        column-gap: 12px;
        row-gap: 12px;

        .moment {
          &:nth-child(1) {
            grid-row: 9 / span 4;
            grid-column: 4 / 5;
          }
          &:nth-child(2) {
            grid-row: 8 / span 5;
            grid-column: 2 / 3;
          }
          &:nth-child(3) {
            grid-row: 6 / span 7;
            grid-column: 3 / 4;
          }
          &:nth-child(4) {
            grid-row: 1 / span 8;
            grid-column: 4 / 5;
          }
          &:nth-child(5) {
            grid-row: 1 / span 5;
            grid-column: 3 / 4;
          }
          &:nth-child(6) {
            grid-row: 6 / span 7;
            grid-column: 1 / 2;
          }
          &:nth-child(7) {
            grid-row: 1 / span 7;
            grid-column: 2 / 3;
          }
          &:nth-child(8) {
            grid-row: 1 / span 5;
            grid-column: 1 / 2;
          }
        }
      }
    }
  }
}

.socials-block {
  position: relative;
  overflow: hidden;
  margin-bottom: 80px;

  .inner-wrp {
    h2 {
      margin-bottom: 24px;
    }
    .social-buttons-wrp {
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0px 0px 16px 0px;
      padding: 0px;

      li {
        margin-bottom: 0px;
        
        .social-button {
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          column-gap: 8px;
          margin-right: 8px;
          margin-bottom: 8px;
          padding: 7px 16px 6px 16px;
          font-size: 14px;
          font-weight: 500;
          line-height: 135%;
          color: #19849B;
          letter-spacing: 0.7px;
          text-transform: capitalize;
          background-color: #C9E9F0;
          border-radius: 54px;

          svg {
            width: 15px;
          }
        }

        &:last-child {
          .social-button {
            margin-right: 0px;
          }
        }
        &:before {
          display: none;
        }
      }
    }
    .social-swiper {
      overflow: visible;

      .swiper-wrapper {
        .swiper-slide {
          img {
            border-radius: 4px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 140px;
  }
}

footer.footer {
  padding: 20px 0px !important;

  ul {
    list-style: none;
    display: grid;
    grid-auto-flow: column;
    column-gap: 16px;
    justify-content: center;
    margin: 0px;
    padding: 0px;

    li {
      margin: 0px;

      a {
        font-size: 12px;
        font-weight: 337;
        line-height: 140%;
        color: #333333;
        text-decoration: underline;
      }
      small {
        font-size: 12px;
        font-weight: 337;
        line-height: 140%;
        color: #333333;
      }
      &:before {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 36px 0px 40px 0px !important;

    ul {
      column-gap: 32px;

      li {
        a, small {
          font-size: 14px;
        }
      }
    }
  }
}