$color-white-rgb: 255, 255, 255;
$color-gray-900-rgb: 33, 37, 41;
$color-primary-rgb: 254, 105, 106;
$bs-dark-rgb: 33,37,41;

.custom-colors {
  .btn-scroll-top-icon {
    color: $white;
  }
  // primary color elements
  .text-hover-primary:hover {
    color: var(--bs-primary, #19849B) !important;
  }
  a, .dropdown-menu li:hover > .dropdown-item, .widget-list-link:hover, .btn-wishlist:hover, .ci-heart-filled,
  .nav-link-style.active, .active > .nav-link-style {
    color: var(--bs-primary, #19849B);
  }
  .product-meta {
    color: #7d879c;

    &:hover {
      color: #4b566b;
    }
  }
  .dropdown-item, .widget-list-link, .nav-link, .widget-product-title > a, .accordion-button:not(.collapsed):hover,
  .page-link, .size-link {
    color: #4b566b;
  }
  .widget-product-title > a:hover {
    color: var(--bs-primary, #19849B);
  }
  .nav-link-style, .accordion-button, #main-header #profile-menu .dropdown-menu li a {
    color: #4b566b;

    &:hover {
      color: var(--bs-primary, #19849B);
    }
  }
  .product-title > a, .btn-wishlist, .blog-entry-title > a {
    color: #373f50;

    &:hover {
      color: var(--bs-primary, #19849B);
    }
  }
  .steps-light .step-item, .steps-light .step-item {
    &.active {
      .step-count, .step-progress {
        background-color: var(--bs-primary, #19849B);
      }
    }
  }
  .navbar-tool .navbar-tool-label, .page-item.active .page-link, .page-size-item.active .size-link {
    background-color: var(--bs-primary, #19849B);
  }
  .page-item.active > .page-link, .page-size-item.active > .size-link {
    box-shadow: 0 0.5rem 1.125rem -0.425rem rgba(var(--bs-primary-rgb, $color-primary-rgb), 0.9);
  }
  .navbar-light .nav-item {
    &:hover .nav-link {
      &:not(.disabled), &:not(.disabled) > i {
        color: var(--bs-primary, #19849B);
      }
    }
  }
  .form-select:focus, .form-control:focus {
    border-color: rgba(var(--bs-primary-rgb, $color-primary-rgb), 0.5);
  }
  .form-check-input:checked ~ .form-option-label {
    border-color: var(--bs-primary, #19849B);
    color: var(--bs-primary, #19849B);
  }

  // buttons
  .btn-primary {
    color: var(--cc-btn-primary-text, #ffffff);
    background-color: var(--cc-btn-primary-bg, #19849B);
    border-color: var(--cc-btn-primary-bg, #19849B);

    &.btn-shadow {
      box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(var(--cc-btn-primary-bg-rgb, 254, 105, 106), 0.9);
    }
    &:hover, &:active, &:focus, &.active, &.show {
      color: var(--cc-btn-primary-text-hover, #ffffff);
      border-color: var(--cc-btn-primary-bg-hover, #fe3638);
      background-color: var(--cc-btn-primary-bg-hover, #fe3638);
    }
  }
  .btn-outline-primary {
    color: var(--cc-btn-primary-bg, #19849B);
    border-color: rgba(var(--cc-btn-primary-bg-rgb, 254, 105, 106), 0.35);

    &:hover, &:active, &:focus, &.active, &.show {
      color: var(--cc-btn-primary-text-hover, #ffffff);
      border-color: var(--cc-btn-primary-bg, #fe3638);
      background-color: var(--cc-btn-primary-bg, #fe3638);
    }
  }
  .btn-outline-danger {
    color: #f34770;
  }


  .topbar {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--cc-top-bar-rgb, --bs-dark-rgb), var(--bs-bg-opacity)) !important;

    .topbar-text,
    .topbar-link {
      color: var(--cc-top-bar-text, $topbar-dark-color);
      > i { color: var(--cc-top-bar-icon, $topbar-dark-icon-color); }
      &.text-muted,
      > .text-muted {
        --bs-text-muted-opacity: .45;
        color: rgba(var(--cc-top-bar-text-muted-rgb, $white), var(--cc-top-bar-text-muted-opacity, --bs-text-muted-opacity)) !important;
      }
    }
    .topbar-link {
      &:hover { color: var(--cc-top-bar-text-hover, $topbar-dark-hover-color); }
      &.active { color: var(--cc-top-bar-text-active, $topbar-dark-active-color); }
    }
    .show.topbar-link { color: var(--cc-top-bar-text-active, $topbar-dark-active-color); }
    .tns-carousel [data-controls] {
      color: $topbar-dark-color;
      &:hover { color: $topbar-dark-hover-color; }
    }
  }

  // page-title-overlap
  .page-title-overlap {
    background-color: rgba(var(--cc-title-overlap-bg-rgb, $bs-dark-rgb), var(--cc-title-overlap-bg-opacity, 1)) !important;

    h1, h2, h3, h4, h5, h6 {
      color: var(--cc-title-overlap-heading, #ffffff) !important;
    }
    nav[aria-label="breadcrumb"] {
      .breadcrumb-item {
        color: rgba(var(--cc-title-overlap-bc-text-rgb, $color-white-rgb), var(--cc-title-overlap-bc-text-opacity, 1)) !important;

        > a {
          color: rgba(var(--cc-title-overlap-bc-text-rgb, $color-white-rgb), var(--cc-title-overlap-bc-text-opacity, 1)) !important;
        }
        &:before {
          color: rgba(var(--cc-title-overlap-bc-sep-rgb, $color-white-rgb), var(--cc-title-overlap-bc-sep-opacity, 0.5)) !important;
        }
      }
    }
  }
  .sort_block {
    > label, span {
      color: rgba(var(--cc-title-overlap-sort-text-rgb, $color-white-rgb), var(--cc-title-overlap-sort-text-opacity, 0.75)) !important;
      opacity: 1 !important;
    }
  }

  // main footer
  footer.footer {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--cc-footer-rgb, --bs-dark-rgb), var(--cc-footer-opacity, --bs-bg-opacity))!important;

    h1, h2, h3, h4, h5, h6 {
      color: var(--cc-footer-headings, #ffffff) !important;
    }
    a {
      //color: rgba(var(--cc-footer-links-rgb, $color-primary-rgb), var(--cc-footer-links-opacity, 0.65)) !important;

      &:hover, &:focus, &:active {
        //color: rgba(var(--cc-footer-links-hover-rgb, $color-primary-rgb), var(--cc-footer-links-hover-opacity, 1)) !important;
      }
    }

    // subscribe forma
    #subscribers_subscribe {
      h1, h2, h3, h4, h5, h6 {
        color: var(--cc-footer-subscribe-heading, #ffffff) !important;
      }
      .form-text {
        color: rgba(var(--cc-footer-subscribe-text-rgb, $color-white-rgb), var(--cc-footer-subscribe-text-opacity, 0.5)) !important;
      }
      .btn {
        color: var(--cc-footer-subscribe-button-text, #ffffff) !important;
        background-color: var(--cc-footer-subscribe-button-bg, #fe8080) !important;
        border-color: var(--cc-footer-subscribe-button-bg, #fe8080) !important;


        &:hover, &:active, &:focus, &.active, &.show {
          color: var(--cc-footer-subscribe-button-text-hover, #ffffff) !important;
          border-color: var(--cc-footer-subscribe-button-bg-hover, #fe3638) !important;
          background-color: var(--cc-footer-subscribe-button-bg-hover, #fe3638) !important;
        }
      }
    }

    // bottom footer
    > div:not(.container) {
      --bs-bg-opacity: 1;
      color: rgba(var(--cc-footer-2-copyright-text-rgb, $color-white-rgb), var(--cc-footer-2-copyright-text-opacity, 0.5)) !important;
      background-color: rgba(var(--cc-footer-2-rgb, $color-gray-900-rgb), var(--cc-footer-2-opacity, --bs-bg-opacity))!important;

      a {
        color: rgba(var(--cc-footer-2-copyright-links-rgb, $color-primary-rgb), var(--cc-footer-2-copyright-links-opacity, 1)) !important;

        &:hover, &:focus, &:active {
          color: rgba(var(--cc-footer-2-copyright-links-hover-rgb, $color-primary-rgb), var(--cc-footer-2-copyright-links-hover-opacity, 1)) !important;
        }
      }
      .btn-social {
        background-color: rgba(var(--cc-footer-2-social-icon-bg-rgb, $color-gray-900-rgb), var(--cc-footer-2-social-icon-bg-opacity, --bs-bg-opacity))!important;
        color: rgba(var(--cc-footer-2-social-icon-color-rgb, $color-gray-900-rgb), var(--cc-footer-2-social-icon-color-opacity, --bs-bg-opacity))!important;

        &:hover, &:focus, &:active {
          background-color: rgba(var(--cc-footer-2-social-icon-bg-hover-rgb, $color-gray-900-rgb), var(--cc-footer-2-social-icon-bg-hover-opacity, --bs-bg-opacity))!important;
          color: rgba(var(--cc-footer-2-social-icon-color-hover-rgb, $color-gray-900-rgb), var(--cc-footer-2-social-icon-color-hover-opacity, --bs-bg-opacity))!important;
        }
      }
    }
    #languagesMenuFooter {
      .btn-outline-light {
        color: var(--cc-footer-2-lang-text, #ffffff) !important;
        border-color: rgba(var(--cc-footer-2-lang-border-rgb, $color-primary-rgb), var(--cc-footer-2-lang-border-opacity, 0.12)) !important;

        &:hover, &:focus, &:active {
          color: var(--cc-footer-2-lang-text-hover, #ffffff) !important;
          background-color: var(--cc-footer-2-lang-bg-hover, $color-white-rgb) !important;
          border-color: var(--cc-footer-2-lang-bg-hover, $color-white-rgb) !important;
        }
      }
      .dropdown-menu {
        background-color: var(--cc-footer-2-lang-bg-hover, $color-white-rgb) !important;
      }
    }
  }
}
