@use "sass:map";

.main-header-inner-wrp {
  background-color: #F5E8DF;

  .navbar {
    padding-top: 54px;

    .container {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 3fr) minmax(0, 1fr);

      .content_layout_checkout & {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-areas: "logo help"
                             "steps steps";

        .navbar-brand {
          grid-area: logo;
          justify-self: start;
        }
        .steps {
          grid-area: steps;
        }
        .checkout-help {
          grid-area: help;
          justify-self: end;
        }
      }
    }
  }
  .navbar-brand {
    justify-self: center;
    margin: 0px;
    padding: 0px;

    img {
      width: 111px;
    }
  }
  .navbar-toggler {
    justify-self: start;
    padding: 0px 0px 0px 3px;

    .menu-icon {}
    .close-icon {
      display: none;
    }
    .menu-open & {
      .menu-icon {
        display: none;
      }
      .close-icon {
        display: block;
      }
    }
  }
  #cartInfo {
    justify-self: end;

    a {
      position: relative;
      display: block;
      padding-top: 7px;
      padding-right: 4px;

      .cart-count {
        position: absolute;
        top: -2px;
        left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        color: #ffffff;
        font-size: 8px;
        font-weight: 700;
        line-height: initial;
        letter-spacing: 0px;
        background: #19849B;
        border: 2px solid #F5E8DF;
        border-radius: 10px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .navbar {
      padding-top: 43px;

      .container {
        grid-template-columns: minmax(0, 190px) minmax(0, 1fr) minmax(0, 160px);
        column-gap: 20px;

        .content_layout_checkout & {
          grid-template-columns: minmax(0, 150px) minmax(0, 1fr) minmax(0, 150px);
          grid-template-areas: "logo steps help";

          .steps {

          }
        }
      }
    }
    .navbar-brand {
      justify-self: start;

      img {
        width: 144px;
      }
    }
    #cartInfo {
      a {
        padding-top: 0px;
        padding-right: 0px;

        svg {
          margin-right: 7px;
          width: 22px;
          height: 22px;
        }
        span {
          font-size: 18px;
          font-weight: 500;
          line-height: 135%;
          color: #1C3E56;
          letter-spacing: 0.7px;
        }
        .cart-count {
          top: -3px;
          left: 14px;
        }
      }
    }
  }
}

#main-menu-wrp {
  ul {
    li {
      &:before {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    z-index: 99;
    padding: 16px 20px 20px 20px;
    background-color: #F5E8DF;
    width: 100%;

    .main-header-menu {
      display: grid;
      row-gap: 2px;

      .nav-item {
        margin-bottom: 0px;
        border-radius: 0px;

        a {
          display: flex;
          align-items: center;
          padding: 19px 16px;
          font-size: 18px;
          font-weight: 500;
          line-height: 135%;
          letter-spacing: 0.6px;
          color: #1C3E56 !important;

          .before-icon {
            margin-right: 12px;
          }
          .after-icon {
            margin-left: 7px;
          }
        }
        &:first-child {
          border-radius: 4px 4px 0px 0px;
        }
        &:last-child {
          border-radius: 0px 0px 4px 4px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    justify-self: center;

    .main-header-menu {
      .nav-item {
        a {
          padding: 16px 14px 14px 14px;
          font-size: 18px;
          font-weight: 500;
          line-height: 135%;
          color: #1C3E56 !important;
          letter-spacing: 0.7px;
        }
      }
    }
  }
}
.navbar-expand-lg {
  .navbar-collapse {
    position: relative;

    .navbar-nav {
      .nav-item {
        &.fullwidth {
          position: static;
          > .dropdown-menu {
            left: 0;
            right: 0;
          }
          .mega-dropdown-column {
            @include media-breakpoint-up(lg) {
              width: 19rem;
              max-width: 19rem;
            }
          }
        }
      }
    }
  }
}