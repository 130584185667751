.cart-detailed-empty {
  display: grid;
  grid-template-columns: minmax(0, 5fr) minmax(0, 2fr);
  align-items: start;
  margin-left: auto;
  margin-right: auto;
  padding-top: 11px;
  max-width: 784px;

  .text-wrp {
    margin-left: -30px;
    padding: 30px 18px 30px 28px;
    text-align: center;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

    h1 {
      letter-spacing: 0px;
    }
    .desc {
      margin-bottom: 20px;
    }
  }
  .orijus-empty {
    position: relative;
    z-index: -1;
    margin-left: -72px;
    width: 284px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 41px;

    .text-wrp {
      grid-template-columns: minmax(0, 14fr) minmax(0, 5fr);
      margin-left: 0px;
      padding: 65px 30px 65px 30px;

      h1 {
        margin-bottom: 21px;
      }
      .desc {
        margin-bottom: 32px;
      }
    }
    .orijus-empty {
      width: 301px;
      margin-top: -32px;
      margin-left: -63px;
    }
  }
}

#cart_detailed {
  margin-left: auto;
  margin-right: auto;
  max-width: 620px;
  padding-bottom: 150px;
  overflow: hidden;

  .cart-detailed-top-wrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    .btn {
      align-self: start;
      display: flex;
      align-items: center;
      margin-top: 3px;
      margin-left: 20px;
    }
  }
  .cart-detailed-summary-wrp {
    padding: 20px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(6, 19, 54, 0.10);

    .summary-lines {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 20px;
      margin-bottom: 18px;

      dt, dd {
        margin: 0px;
        font-size: 16px;
        font-weight: 500;
        line-height: 135%;
        color: #1C3E56;

        &.total {
          font-size: 24px;
          font-weight: 600;
          line-height: 110%;
          color: #333333;
        }
      }
      dd {
        text-align: right;
      }
    }
    > .btn {
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        right: 19px;
        transform: translateY(-50%);
      }
    }
  }

  &.no-items {
    max-width: initial;
  }
  @include media-breakpoint-down(lg) {
    .cart-detailed-summary-wrp {
      position: fixed;
      left: 50%;
      bottom: 0;
      z-index: 2;
      max-width: 580px;
      width: 100%;
      transform: translateX(-50%);
    }
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 100px;

    .cart-detailed-top-wrp {
      margin-bottom: 41px;

      .btn {
        align-self: end;
      }
    }
    .cart-detailed-summary-wrp {
      padding: 32px 40px 40px 40px;

      .summary-lines {
        margin-bottom: 24px;

        dt, dd {
          font-size: 18px;

          &.total {
            font-size: 28px;
          }
        }
      }
    }
  }
}

#cart_items {
  &.contains-items {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 8px;
    margin-bottom: 8px;

    .cart-item {
      position: relative;
      display: grid;
      grid-template-columns: minmax(0, 88px) minmax(0, 1fr);
      column-gap: 24px;
      padding: 24px 24px 32px 24px;
      border-radius: 4px;
      background: #ffffff;
      box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

      .photo-wrp {
        align-self: start;
        position: relative;
        overflow: hidden;
        padding: 6px;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 4px 10px 0px rgba(8, 19, 48, 0.08);

        .texts-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 30px);
          column-gap: 8px;
          align-items: center;
          padding-top: 3px;
          height: 10px;

          .phrase {
            text-align: left;
            font-size: 2px;
            line-height: 110%;
            color: #333333;
          }
          .quote {
            justify-self: end;
            max-width: 30px;
            text-align: right;
            font-size: 1px;
            line-height: 110%;
            color: #333333;
          }
        }
        .view-btn {
          position: absolute;
          left: 50%;
          top: 50%;
          display: flex;
          align-items: center;
          padding: 6px;
          height: 28px;
          background-color: #C9E9F0;
          border-radius: 40px;
          transform: translateX(-50%) translateY(-50%);
        }
      }
      .texts-wrp {
        .product-title {
          margin-top: 12px;
          margin-bottom: 8px;
          font-size: 24px;
          font-weight: 600;
          line-height: 110%;
          color: #333333;

          a {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;

            &:hover {
              color: #19849B;
            }
          }
        }
        .price-wrp {
          margin-bottom: 14px;
          font-size: 16px;
          font-weight: 500;
          line-height: 135%;
          color: #333333;
        }
        .greeting {
          overflow: hidden;
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: 500;
          line-height: 135%;
          color: #1C3E56;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          opacity: 0.6;
        }
        .quantity_selector {
          .quantity_control {
            display: grid;
            grid-template-columns: minmax(0, 40px) minmax(0, 1fr) minmax(0, 40px);
            width: 120px;
            border-radius: 4px;
            border: 1px solid #C7D2DA;
            background: #FFF;

            input {
              padding: 0px;
              width: initial;
              border: none;
            }
            .input-group-btn {
              .btn {
                padding: 6px 10px 8px 10px;
              }
            }
          }
        }
      }
      .cart-remove-wrp {
        position: absolute;
        top: -3px;
        right: 13px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.contains-items {
      .cart-item {
        grid-template-columns: minmax(0, 141px) minmax(0, 1fr);
        column-gap: 32px;
        padding: 28px 32px 48px 32px;

        .photo-wrp {
          .texts-wrp {
            grid-template-columns: minmax(0, 1fr) minmax(0, 45px);
            padding-top: 5px;
            height: 14px;

            .phrase {
              font-size: 4px;
            }
            .quote {
              max-width: 45px;
              font-size: 2px;
            }
          }
        }
        .texts-wrp {
          .product-title {
            font-size: 28px;
          }
          .price-wrp {
            margin-bottom: 16px;
            font-size: 18px;
          }
          .greeting {
            font-size: 16px;
          }
        }
        .cart-remove-wrp {
          top: 1px;
          right: 19px;
        }
      }
    }
  }
}

#cart_summary {
  padding: 30px 20px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(6, 19, 54, 0.10);

  ul {
    display: grid;
    row-gap: 14px;
    margin: 0px 0px 24px 0px;
    padding: 0px;

    li {
      margin: 0px;
      font-size: 16px;
      font-weight: 500;
      line-height: 135%;
      letter-spacing: 0.5px;

      &.total {
        font-size: 24px;
        font-weight: 600;
        line-height: 110%;
      }
      &:before {
        display: none;
      }
    }
  }
  > .btn {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
    }
  }
  @include media-breakpoint-down(lg) {
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 2;
    max-width: 580px;
    width: 100%;
    transform: translateX(-50%);
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding: 32px 40px;

    ul {
      row-gap: 16px;
      margin: 0px 0px 24px 0px;

      li {
        font-size: 18px;

        &.total {
          font-size: 28px;
        }
      }
    }
  }
}

#delivery_options {
  padding-bottom: 300px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0px;
  }
}

.cart-type-wrp {
  &#delivery_options {

    #type_options_form {
      .coupon-radios-wrp {
        display: grid;
        row-gap: 8px;

        .coupon-radio {
          label {
            position: relative;
            display: grid;
            padding: 34px 135px 15px 24px;
            color: #333333;
            border-radius: 4px;
            background-color: #ffffff;
            background-repeat: no-repeat;
            cursor: pointer;
            transition: all .3s;

            .indicator {
              position: absolute;
              top: -2px;
              left: -2px;
              padding: 3px 16px;
              font-size: 12px;
              font-weight: 337;
              line-height: 140%;
              color: #ffffff;
              border-radius: 7px 0px;
              background-color: #19849B;
              opacity: 0;
              transition: all .3s;
            }
            .price {
              margin-bottom: 7px;
              font-size: 24px;
              font-weight: 600;
              line-height: 110%;
            }
            .title {
              margin-bottom: 14px;
              font-size: 16px;
              font-weight: 500;
              line-height: 135%;
            }
            .desc {
              margin-bottom: 16px;
              font-size: 14px;
              font-weight: 500;
              line-height: 135%;
              color: #1C3E56;
              opacity: 0.6;
            }
            .view-coupon {
              justify-self: start;
              display: flex;
              align-items: center;
              padding: 5px;

              font-size: 12px;
              font-weight: 337;
              line-height: 140%;
              color: #19849B;
              border-radius: 4px;
              background: #ffffff;
              box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);
            }

            &[for="coupon_1"] {
              background-image: url('/images/frontend/delivery/coupon-e.png');
              background-size: 161px 164px;
              background-position: calc(100% + 44px) calc(100% + 14px);
            }
            &[for="coupon_2"] {
              background-image: url('/images/frontend/delivery/coupon-f.png');
              background-size: 200px 167px;
              background-position: calc(100% + 52px) calc(100% + 6px);
            }
          }
          input {
            display: none;

            &:checked ~ label {
              box-shadow: 0px 0px 0px 3px #19849B;

              .indicator {
                opacity: 1;
              }
            }
          }
        }
      }

      #coupon1Modal {
        .modal-dialog {
          .modal-content {
            .modal-header {

            }
            .modal-body {

            }
          }
        }
      }
      #coupon2Modal {
        .modal-dialog {
          .modal-content {
            .modal-header {

            }
            .modal-body {
              overflow: hidden;

              .coupon2ModalSwiper {
                overflow: visible;

                .swiper-wrapper {
                  .swiper-slide {
                    display: grid;
                    justify-content: center;
                    text-align: center;

                    img {
                      width: 100%;
                    }
                    span {
                      margin-top: 12px;
                      font-size: 16px;
                      font-weight: 500;
                      line-height: 135%;
                      color: #333333;
                    }
                  }
                }
                .swiper-pagination {
                  bottom: -38px;

                  .swiper-pagination-bullet {
                    margin: 0px 2px;
                    background: #C7D2DA;
                    opacity: 1;

                    &.swiper-pagination-bullet-active {
                      background: #19849B;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      #type_options_form {
        .coupon-radios-wrp {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 8px;
          margin-bottom: 41px;

          .coupon-radio {
            label {
              padding: 40px 40px 193px 40px;

              .price {
                font-size: 28px;
              }
              .title {
                font-size: 18px;
              }
              .desc {
                font-size: 16px;
              }
              .view-coupon {
                padding: 6px 8px;
                font-size: 14px;
              }

              &[for="coupon_1"] {
                background-position: center calc(100% + 15px);
                background-size: 176px 179px;
              }
              &[for="coupon_2"] {
                background-position: center calc(100% + 0px);
                background-size: 205px 171px;
              }
            }
          }
        }

        #coupon1Modal {
          .modal-dialog {
            max-width: 395px;

            .modal-content {
              .modal-header {}
            }
          }
        }
        #coupon2Modal {
          .modal-dialog {
            max-width: 1040px;

            .modal-content {
              .modal-header {}
              .modal-body {
                padding: 0px 40px 40px 40px;

                .coupon2ModalSwiper {
                  .swiper-wrapper {
                    .swiper-slide {
                      img {}
                      span {
                        font-size: 18px;
                      }
                    }
                  }
                  &:not(.swiper-initialized) {
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    column-gap: 7px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cart-delivery-wrp {
  &#delivery_options {
    #delivery_type_options {
      margin-bottom: 30px;

      .multiple-delivery {
        .form-wrapper {
          display: grid;
          row-gap: 8px;

          .form-check {
            padding: 0px;

            label {
              position: relative;
              padding: 14px 20px 15px 46px;
              width: 100%;
              border-radius: 4px;
              background: #ffffff;
              box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);
              cursor: pointer;
              transition: all .3s;

              .top-wrp {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                .title {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 135%;
                }
                .price {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 135%
                }
              }
              .desc {
                margin: 0px;
                font-size: 14px;
                font-weight: 500;
                line-height: 135%;
                color: #1C3E56;
                opacity: 0.6;
              }
              &:before {
                content: "";
                position: absolute;
                top: 20px;
                left: 18px;
                display: block;
                width: 18px;
                height: 18px;
                box-shadow: 0px 0px 0px 1px #C7D2DA inset;
                border-radius: 9px;
                transition: all .3s;
              }
              &:after {
                content: "";
                position: absolute;
                top: 24px;
                left: 22px;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 9px;
                background-color: #19849B;
                transition: all .3s;
                opacity: 0;
              }
            }
            input {
              display: none;

              &:checked ~ label {
                box-shadow: 0px 0px 0px 3px #19849B;

                &:before {
                  border: none;
                  box-shadow: 0px 0px 0px 2px #19849B inset;
                }
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .single-delivery {
        padding: 28px 24px 28px 24px;
        border-radius: 4px;
        background: #ffffff;
        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

        .form-wrapper {
          row-gap: 0px;

          .top-wrp {
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
            column-gap: 20px;
            margin-bottom: 4px;

            .title {
              font-size: 16px;
              font-weight: 700;
              line-height: 135%;
            }
            .price {
              font-size: 16px;
              font-weight: 700;
              line-height: 135%;
            }
          }
          .desc {
            margin-bottom: 18px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
            color: #1C3E56;
          }
        }
      }
    }
    #delivery_type_fields {
      .inner-wrp {
        margin-bottom: 30px;
        padding: 40px 18px 40px 18px;
        border-radius: 4px;
        background: #ffffff;
        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

        > h2 {
          margin-bottom: 18px;
          font-size: 16px;
          font-weight: 700;
          line-height: 135%;
          color: #333333;
          font-family: 'Roboto Flex', sans-serif;
        }
        .form-wrapper {

        }
        .wrp_company {
          padding-top: 22px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &#delivery_options {
      #delivery_type_options {
        margin-bottom: 20px;

        .multiple-delivery {
          .form-wrapper {
            .form-check {
              label {
                padding: 14px 24px 15px 52px;
                min-height: 100px;
                width: 100%;

                .top-wrp {
                  margin-bottom: 4px;

                  .title {
                    font-size: 18px;
                  }
                  .price {
                    font-size: 18px;
                  }
                }
                .desc {
                  font-size: 16px;
                }
                &:before {
                  top: 15px;
                  left: 24px;
                }
                &:after {
                  top: 19px;
                  left: 28px;
                }
              }
            }
          }
        }
        .single-delivery {
          padding: 32px 32px 32px 32px;

          .form-wrapper {
            .top-wrp {
              margin-bottom: 8px;

              .title {
                font-size: 18px;
                font-weight: 800;
              }
              .price {
                font-size: 18px;
                font-weight: 800;
              }
            }
            .desc {
              margin-bottom: 14px;
              font-size: 16px;
            }
          }
        }
      }
      #delivery_type_fields {
        .inner-wrp {
          margin-bottom: 20px;
          padding: 32px 32px 40px 32px;

          > h2 {
            font-size: 18px;
            font-weight: 800;
          }
          .wrp_company {}
        }
      }
    }
  }
}

#orderPaymentForm {
  padding-bottom: 330px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0px;
  }
}

#paymentMethods {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 6px;

  .payment-option {
    display: inherit;
    grid-template-columns: inherit;
    row-gap: inherit;

    .payment-item {
      position: relative;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 95px);
      align-items: center;
      column-gap: 20px;
      padding: 20px 16px 20px 46px;
      border-radius: 4px;
      background: #ffffff;
      box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 135%;
      }
      img {
        justify-self: end;
        height: 16px;
        width: auto;
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 18px;
        display: block;
        width: 18px;
        height: 18px;
        box-shadow: 0px 0px 0px 1px #C7D2DA inset;
        border-radius: 9px;
        transform: translateY(-50%);
        transition: all .3s;
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 22px;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 9px;
        background-color: #19849B;
        transform: translateY(-50%);
        transition: all .3s;
        opacity: 0;
      }
      &.active {
        box-shadow: 0px 0px 0px 3px #19849B;

        &:before {
          border: none;
          box-shadow: 0px 0px 0px 2px #19849B inset;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .payment-option {
      .payment-item {
        padding: 20px 24px 20px 52px;

        .title {
          font-size: 18px;
        }
        &:before {
          left: 24px;
        }
        &:after {
          left: 28px;
        }
      }
    }
  }
}
.agreements-wrp {
  padding: 28px 24px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px rgba(6, 19, 54, 0.05);

  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 135%;
    font-family: 'Roboto Flex', sans-serif;
    color: #1C3E56;
  }
  a {
    color: #19849B;
  }
  .form-wrapper {
    .form-group {
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}