.content_layout_checkout {
  .main-header-inner-wrp {
    .navbar {
      padding-bottom: 9px;
      box-shadow: 0px 0px 15px 10px rgba(107, 120, 129, 0.05);

      .container {
        .steps {
          margin-top: 20px;

          .step-item {
            padding: 12px 0px;

            .step-label {
              padding: 0px;
              font-size: 12px;
              font-weight: 337;
              line-height: 140%;
              color: #1C3E56;
              opacity: 0.3;
              transition: all .3s;

              &:hover {
                opacity: 0.6;
              }
            }
            &.current {
              .step-label {
                color: #19849B;
                opacity: 1;
              }
            }
          }
        }
        .checkout-help {}
      }
    }
  }
  #content_wrapper {
    padding-top: 40px;

    .container {
      margin-left: auto;
      margin-right: auto;
      max-width: 620px;
    }
  }
  .form-wrapper {
    .form-group {
      margin-bottom: 15px;

      label {
        margin-bottom: 7px;
        font-size: 14px;
        font-weight: 500;
        line-height: 135%;
        color: #1C3E56;
        opacity: 0.8;
      }
      .form-control {
        padding: 12px 16px;

        &:focus {
          border-color: #19849B;
          box-shadow: 0px 0px 0px 1px #19849B;
        }
      }
      .form-select {
        padding: 12px 16px;

        &:focus {
          border-color: #19849B;
          box-shadow: 0px 0px 0px 1px #19849B;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .main-header-inner-wrp {
      .navbar {
        box-shadow: none;

        .container {
          .steps {
            display: grid;
            grid-auto-flow: column;
            column-gap: 30px;
            justify-content: center;
            margin-top: 0px;

            .step-item {
              flex-grow: 0;
              flex-shrink: 0;
              white-space: nowrap;

              .step-label {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    #content_wrapper {
      padding-top: 52px;
    }
  }
}

.thank_you_page {
  position: relative;

  .title {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 400;
    line-height: 130%;
  }
  .desc {
    margin-bottom: 23px;
    padding: 0px 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 135%;
    color: #333333;
  }
  .btn {
    min-width: 264px;
  }
  .orijus-aciu {
    padding-bottom: 40px;

    .orijus {
      position: relative;
      z-index: -1;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 294px;
      width: 100%;
    }
  }
  .confeti-1, .confeti-2 {
    position: absolute;
    transition: all .3s;
  }
  .confeti-1 {
    left: 0px;
    bottom: 236px;
    z-index: -1;
    width: 173px;
  }
  .confeti-2 {
    right: 0px;
    bottom: -55px;
    width: 228px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: -20px;

    .title {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
      max-width: 580px;
      font-size: 48px;
      line-height: 110%;
    }
    .desc {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 32px;
      max-width: 480px;
      font-size: 20px;
      font-weight: 500;
      line-height: 150%;
    }
    .btn {}
    .orijus-aciu {
      padding-bottom: 60px;

      .orijus {
        margin-top: -56px;
        max-width: 439px;
      }
    }
    .confeti-1 {
      left: 0px;
      top: -103px;
      z-index: -1;
      width: 301px;
    }
    .confeti-2 {
      right: 0px;
      bottom: 0px;
      width: 398px;
    }
  }
}

.checkout-backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  display: block;
  background-color: #1C3E56;
  opacity: 0;
  transition: all 0.3s;

  .help-open & {
    z-index: 9;
    display: block;
    opacity: 0.6;
  }
  @include media-breakpoint-up(lg) {
    opacity: 0 !important;
  }
}
.checkout-help {
  > button {
    padding: 3px 4px 4px 6px;
    font-size: 12px;
    font-weight: 337;
    line-height: 140%;
    color: #19849B;
    letter-spacing: 0.5px;
    border: none;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);
  }
  .checkout-help-popup {
    position: fixed;
    top: -100%;
    left: 0px;
    right: 0px;
    padding: 20px 20px 20px 20px;
    max-height: 100%;
    background: #F5E8DF;
    box-shadow: 0px 4px 34px 0px rgba(6, 19, 54, 0.05);
    transition: all .3s;

    .help-popup-header {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 33px;
      padding-top: 38px;

      .close-btn {
        justify-self: start;
        margin-left: -6px;
        background: none !important;
        border: none !important;
        box-shadow: none !important;
      }
      a {
        justify-self: end;
      }
    }
    .help-popup-body {
      ul {
        display: grid;
        row-gap: 2px;
        margin: 0px;
        padding: 0px;

        li {
          margin: 0px;
          background-color: #ffffff;

          &.top-wrp {
            display: grid;
            row-gap: 5px;
            padding: 20px 24px;

            .title {
              font-size: 24px;
              font-weight: 600;
              line-height: 110%;
            }
            .desc {
              font-size: 14px;
              font-weight: 500;
              line-height: 135%;
            }
          }
          &.phone-wrp, &.email-wrp {

            a {
              position: relative;
              display: block;
              padding: 20px 50px 20px 24px;
              font-size: 16px;
              font-weight: 500;
              line-height: 135%;
              color: #1C3E56;

              .link {
                position: absolute;
                top: 50%;
                right: 24px;
                transform: translateY(-50%);
              }
            }
          }
          &:first-child {
            border-radius: 4px 4px 0px 0px;
          }
          &:last-child {
            border-radius: 0px 0px 4px 4px;
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }
  &.open {
    .checkout-help-popup {
      top: 0px;
      z-index: 10;
    }
  }

  @include media-breakpoint-up(lg) {
    position: relative;

    > button {
      padding: 6px 8px 6px 8px;
      font-size: 14px;
    }
    .checkout-help-popup {
      display: none;
      position: absolute;
      top: calc(100% + 9px) !important;
      right: 0px !important;
      left: initial;
      padding: 18px 20px 20px 20px;
      max-height: initial;
      width: 360px;
      border-radius: 4px;

      .help-popup-header {}
      .help-popup-body {
        ul {
          li {
            &.top-wrp {
              display: grid;
              row-gap: 5px;
              padding: 24px 24px 19px 24px;

              .title {
                font-size: 28px;
              }
              .desc {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    &.open {
      .checkout-help-popup {
        display: block;

        .help-popup-header {
          padding-top: 0px;
        }
      }
    }
  }
}