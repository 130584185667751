ul.filters-list {
  li.with-icon {
    list-style: none;
    position: relative;
    i {
      position:absolute;
      margin-left: -20px;
    }
  }
}

.content_layout_product_detailed {
  .main-header {
    .main-header-inner-wrp {
      box-shadow: 0px 0px 15px 10px rgba(107, 120, 129, 0.05);

      .navbar {
        padding-top: 58px;

        .container {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-template-areas: "back cart"
                               "title title";

          .go-back {
            grid-area: back;
            justify-self: start;
            display: flex;
            align-items: center;

            svg {
              margin-right: 4px;
            }
          }
          #cartInfo {
            grid-area: cart;
            justify-self: end;
          }
          h1 {
            grid-area: title;
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: 400;
            line-height: 130%;
          }
        }
      }
    }
  }
  .content_body {
    padding-top: 32px;
  }

  @include media-breakpoint-up(lg) {
    .main-header {
      .main-header-inner-wrp {
        box-shadow: none;

        .navbar {
          padding-top: 46px;

          .container {
            grid-template-columns: minmax(0, 150px) minmax(0, 1fr) minmax(0, 150px);
            grid-template-areas: "back title cart";

            h1 {
              margin: 0px;
              font-size: 48px;
              line-height: 110%;
            }
          }
        }
      }
    }
  }
}

#products_detailed {
  overflow: hidden;

  .product_block {

  }
}

#products_add2cart {
  margin-left: auto;
  margin-right: auto;
  max-width: 580px;

  .product-details {
    max-width: initial;

    .modification_selector {
      overflow: visible;
      margin-bottom: 50px;

      .swiper-slide {
        width: 201px;

        .modification {
          .form-check {
            padding: 0px;
            line-height: 0px;

            input {
              opacity: 0;
            }
            .form-option-label {
              position: relative;
              overflow: hidden;
              padding: 15px;
              width: 100%;
              background-color: #ffffff;
              border-radius: 4px;
              border: none !important;

              .indicator {
                position: absolute;
                top: -2px;
                left: -2px;
                padding: 4px 16px;
                font-size: 12px;
                font-weight: 337;
                line-height: 140%;
                color: #ffffff;
                border-radius: 7px 0px;
                background-color: #19849B;
                opacity: 0;
                transition: all .3s;
              }
              img {
                width: 100%;
              }
              .texts-wrp {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 54px);
                column-gap: 20px;
                align-items: center;
                padding-top: 5px;
                height: 20px;

                .phrase {
                  text-align: left;
                  font-size: 6px;
                  line-height: 110%;
                  color: #333333;
                }
                .quote {
                  justify-self: end;
                  max-width: 54px;
                  text-align: right;
                  font-size: 3px;
                  line-height: 110%;
                  color: #333333;
                }
              }
            }
            .form-check-input:checked ~ .form-option-label {
              box-shadow: 0px 0px 0px 3px rgba(25,132,155,1) inset;
              -webkit-box-shadow: 0px 0px 0px 3px rgba(25,132,155,1) inset;
              -moz-box-shadow: 0px 0px 0px 3px rgba(25,132,155,1) inset;

              .indicator {
                opacity: 1;
              }
              img {
                //position: relative;
                //z-index: -1;
              }
            }
          }
          .modification-name {
            display: block;
            margin-top: 14px;
            font-size: 16px;
            font-weight: 500;
            line-height: 135%;
            color: #333333;
            text-align: center;
          }
        }
      }
    }
    .item-selector-wrp {
      margin-bottom: 48px;

      .item_selector {
        .item-selector-title {
          display: block;
          margin-bottom: 16px;
          font-size: 24px;
          font-weight: 600;
          line-height: 110%;
          color: #19849B;
        }
        .item-selector-options {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 16px;
          row-gap: 16px;

          .form-check {
            margin: 0px;
            padding: 0px;

            label {
              position: relative;
              display: block;
              padding: 13px 15px 13px 48px;
              font-size: 16px;
              font-weight: 500;
              line-height: 135%;
              color: #333333;
              text-align: left;
              border: none;
              border-radius: 4px;
              background: #ffffff;
              box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 18px;
                display: block;
                width: 18px;
                height: 18px;
                box-shadow: 0px 0px 0px 1px #C7D2DA inset;
                border-radius: 9px;
                transform: translateY(-50%);
                transition: all .3s;
              }
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 22px;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 9px;
                background-color: #19849B;
                transform: translateY(-50%);
                transition: all .3s;
                opacity: 0;
              }
            }
            .form-check-input {
              opacity: 0;

              &:checked ~ label {
                box-shadow: 0px 0px 0px 3px #19849B;

                &:before {
                  border: none;
                  box-shadow: 0px 0px 0px 2px #19849B inset;
                }
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .custom-coupon-sum-wrp {
        margin-top: 16px;
        padding: 22px 20px 24px 20px;
        border-radius: 4px;
        background: #ffffff;
        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

        .title {
          display: block;
          font-size: 18px;
          font-weight: 800;
          line-height: 135%;
          color: #333333;
        }
        .desc {
          font-size: 16px;
          font-weight: 500;
          line-height: 135%;
          color: #1C3E56;
          opacity: 0.6;
        }
        .input-group {
          overflow: hidden;
          border-radius: 4px;
          border: 1px solid #C7D2DA;
          background: #ffffff;

          .input-group-prepend {
            .input-group-text {
              padding: 10px 0px 10px 15px;
              font-size: 16px;
              font-weight: 500;
              line-height: 135%;
              border: none;
              border-radius: 0px;
            }
          }
          input {
            padding: 10px 10px 10px 8px;
            font-size: 16px;
            font-weight: 500;
            line-height: 135%;
            border: none;
            border-radius: 0px;
          }
        }
      }
    }
    .greeting-wrp {
      position: relative;
      margin-bottom: 32px;

      .orijus-bubble {
        position: absolute;
        top: 14px;
        right: -28px;
        z-index: -1;
        display: block;
        width: 103px;
        height: 132px;
        background-image: url("/images/frontend/orijus-bubble.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
      .top-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        column-gap: 20px;
        align-items: center;
        margin-bottom: 14px;

        .greeting-title {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%;
          color: #19849B;
          letter-spacing: 0.6px;
        }
        .btn {}
      }
      .desc {
        margin-right: 80px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 135%;
        color: #333333;
        letter-spacing: 0.5px;
      }
      .greeting-input-wrp {
        position: relative;
        line-height: 0px;

        textarea {
          padding: 16px;
          min-height: 140px;
          width: 100%;
          font-size: 16px;
          font-weight: 500;
          line-height: 135%;
          letter-spacing: 0.5px;
          border: none;
          border-radius: 4px;
          background: #ffffff;
          box-shadow: 0px 4px 34px 0px rgba(6, 19, 54, 0.05);
        }
        #greeting-count {
          position: absolute;
          right: 8px;
          bottom: 7px;
          font-size: 12px;
          line-height: 140%;
          color: #C7D2DA;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .product-details {
      padding-top: 36px;

      .modification_selector {
        margin-bottom: 96px;

        .swiper-slide {
          .modification {
            .form-check {
              .form-option-label {
                .indicator {
                  padding: 7px 16px;
                  font-size: 14px;
                }
              }
            }
            .modification-name {
              font-size: 18px;
            }
          }
        }
        .swiper-button-next, .swiper-button-prev {
          width: 40px;
          height: 40px;
          border-radius: 4px;
          background: #ffffff;
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);

          svg {
            width: 11px;
          }
          &.swiper-button-disabled {
            opacity: 0;
          }
          &:before, &:after {
            display: none;
          }
        }
        .swiper-button-next {
          right: -44px;
        }
        .swiper-button-prev {
          left: -44px;
        }
        .swiper-pagination {
          bottom: -38px;

          .swiper-pagination-bullet {
            margin: 0px 2px;
            background: #C7D2DA;
            opacity: 1;

            &.swiper-pagination-bullet-active {
              background: #19849B;
            }
          }
        }
      }
      .item-selector-wrp {
        margin-bottom: 64px;

        .item_selector {
          .item-selector-title {
            margin-bottom: 24px;
            font-size: 28px;
          }
          .item-selector-options {
            grid-template-columns: repeat(3, minmax(0, 1fr));

            .form-check {
              label {
                padding: 14px 15px 14px 48px;
                font-size: 18px;
              }
            }
          }
        }
        .custom-coupon-sum-wrp {
          padding: 24px 24px 32px 24px;

          .title {
            margin-bottom: 3px;
          }
          .desc {
            margin-bottom: 16px;
          }
          .input-group {}
        }
      }
      .greeting-wrp {
        margin-bottom: 40px;

        .orijus-bubble {
          top: 17px;
          right: 7px;
          width: 112px;
          height: 144px;
        }
        .top-wrp {
          .greeting-title {
            font-size: 28px;
          }
          .btn {}
        }
        .desc {
          margin-bottom: 22px;
          max-width: 280px;
          font-size: 16px;
        }
        .greeting-input-wrp {
          textarea {
            min-height: 172px;
          }
        }
      }
    }
  }
}

.greeting-template-backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  background: #1C3E56;
  opacity: 0;
  transition: all .3s;
}
.greeting-template-popup {
  position: fixed;
  top: 0px;
  right: -100%;
  bottom: 0px;
  z-index: 12;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  max-width: 500px;
  width: 100%;
  background: #F5E8DF;
  transition: all .3s;

  .greeting-template-header {
    position: relative;
    padding: 56px 50px 20px 20px;
    box-shadow: 0px 0px 15px 10px rgba(107, 120, 129, 0.05);

    h3 {
      margin: 0px;
      font-size: 24px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: 0.5px;
    }
    .close-popup {
      position: absolute;
      top: 55px;
      right: 14px;
      border: none;
      box-shadow: none;
      background: transparent;
    }
  }
  .greeting-template-body {
    overflow: auto;
    padding: 0px 20px 20px 20px;

    .greetings-list {
      display: grid;
      row-gap: 8px;

      .greeting-item {
        padding: 24px 20px;
        border-radius: 4px;
        background: #ffffff;
        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

        .text {
          display: block;
          margin-bottom: 8px;
          font-size: 16px;
          font-weight: 500;
          line-height: 135%;
          color: #333333;
        }
      }
    }
  }

  &.open {
    right: 0px;

    & + .greeting-template-backdrop {
      z-index: 11;
      opacity: 0.6;
    }
  }
}