// FOR COLORS DO NOT OVERRIDE $theme-colors ARRAY
// OVERRIDE VARIABLES cartzilla/_variables.scss 28-36 lines

$border-radius: 0.3125rem !default;

$font-size-base: 1rem;
$line-height-base: 1.5;

$icons-font-path: '../../../public/fonts/cartzilla';

$enable-grid-classes: true !default;

// lightGallery
$lg-path-fonts: '../../fonts/lg'!default;


$lightBlue: #19849B;